import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import ReactMarkdown from "react-markdown"
import Social from "../components/social.js"
import LayoutAlt from "../components/LayoutAlt.js"

export default function Bible ({ data }) {

  const bsp = data.strapiBiblestudypage
  const shadowmd = "shadow-md"
  const shadowxl = "shadow-xl"
  const translate = "-translate-y-1"
  const transform = "transform"
  const transition = "transition duration-700 ease-in-out"
  const scale = "scale-105"
  const bgcolor = "bg-blue-alt"
  const rounded = "rounded-r-xl"
  // const hovertextcolor = "text-white"
  const textcolor = "text-white"


   
    
  return(
 <LayoutAlt>

       <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Understanding the Bible Truth" />
        <title>ReaCH | Bible Study</title>
        <link rel="canonical" href="https://reachun.org/biblestudy" />
      </Helmet>
    
         <div className="font-montserrat sm:w-3/4 sm:ml-1/8 sm:mt-10  xs:mt-5 xs:p-4 sm:p-0">
        <section className="text-blue-dark">
                <h1 className="font-bold text-2xl">{bsp.title}</h1>
                <p className="font-light sm:text-3xl xs:text-xl mt-2">{bsp.description}<span className="font-bold text-orange-dark"> {bsp.action}</span></p>
            </section>
            <section className="mt-10">
               <div>
                   <h1 className="font-playfairdisplay italic font-bold sm:text-5xl xs:text-4xl text-blue-dark">{bsp.subhead}</h1>
                   <p className="font-opensans text-xl italic text-gray-700 mt-6">
                   <ReactMarkdown children={bsp.bibleverse} />
                   </p>
                  
               </div>
           </section>
           <section className="grid  2xl:gap-8  xl:grid-cols-3 xl:gap-4   md:grid-cols-2 md:gap-4 xs:grid-cols-1 xs:gap-2 my-12">
            {bsp.imgcard.map(cat => (
                <div className={`${transition} ${bgcolor} hover:${cat.hovercolor} ${transform}  hover:${translate}  hover:${scale}  ${cat.rounded} hover:${shadowxl} 
                 ${rounded}  ${textcolor} ${shadowmd}`} key={cat.id}>
                   <div className="lg:mb-20 mb-10">
                        <h1 className="p-4 font-montserrat font-bold">{cat.title}</h1>
                        <img src={cat.image.url} alt="Bible Study" className="xs:w-64 xs:h-32 sm:w-96 sm:h-48 md:w-64 md:h-32 lg:w-80 lg:h-40  xl:w-64 xl:h-32 2xl:w-72 2xl:h-48 3xl:w-96 3xl:h-64 object-cover object-center rounded-r-2xl"/>
                        <p className="font-opensans  mt-8 pl-4 w-3/4 tracking-widest">{cat.description} </p>
                        <p className="font-bold font-opensans underline  pt-4 pl-4 lg:text-lg 2xl:text-xl 3xl:text-2xl"><Link to={`/${cat.path}`}>{cat.pathname}</Link></p>
                    </div>
                </div>
                ))}
            </section>

        </div>
       <Social />
        </LayoutAlt>   
      
       
       
    )

}

export const query = graphql`
  {
    strapiBiblestudypage {
      action
      bibleverse
      description
      id
      title
      verseref
      subhead
      imgcard {
        description
        id
        path
        pathname
        title
        hovercolor
        rounded
        image {
          url
        }
      }
    }
  }
`